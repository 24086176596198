import { css } from 'styled-components';

export const fadeInOutStyles = css`
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
    `}
`;

export const skeletonGradient = css`
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.shoppableVideoSkeletonGradient20},
    ${({ theme }) => theme.colors.shoppableVideoSkeletonGradient30},
    ${({ theme }) => theme.colors.shoppableVideoSkeletonGradient20}
  );
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
`;

export const portraitMobileQuery = (theme) =>
  'screen and (orientation: portrait) and (max-width: ' +
  theme.breakpoints.xs.maxWidth +
  'px)';

export const landscapeMobileQuery = (theme) =>
  'screen and (orientation: landscape) and (max-height: ' +
  theme.breakpoints.xs.maxWidth +
  'px) and (max-width: ' +
  theme.breakpoints.tablet.maxWidth +
  'px)';

/**
 * Media query for mobile phone screen (portrait and landscape). Does not include tablets.
 */
export const getMobileScreenMediaQuery = (theme) => {
  return `${portraitMobileQuery(theme)}, ${landscapeMobileQuery(theme)}`;
};

export function mobilexs(...args) {
  return css`
    @media ${(props) => getMobileScreenMediaQuery(props.theme)} {
      ${css(...args)};
    }
  `;
}

export function largerThanMobileXs(...args) {
  return css`
    @media (min-width: ${({ theme }) => theme.breakpoints.xs.maxWidth}px) {
      ${css(...args)};
    }
  `;
}

export function mobilexsLandscape(...args) {
  return css`
    @media ${(props) => landscapeMobileQuery(props.theme)} {
      ${css(...args)};
    }
  `;
}

export function mobilexsPortrait(...args) {
  return css`
    @media ${(props) => portraitMobileQuery(props.theme)} {
      ${css(...args)};
    }
  `;
}
