import React, { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useProductTracking } from '~/shoppableVideo/utils/eventTracking';
import {
  Picture,
  useProductImages,
  mobile,
  useTheme,
} from '~/techstyle-shared/react-components';
import {
  trackProductClicked,
  useProductListViewed,
} from '~/techstyle-shared/react-products';

const ProductInfoContainer = styled.button`
  --product-card-width: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.productThumbnail.desktop.width +
    2 * carousel.productThumbnail.border}px;
  --product-card-height: ${({ theme: { videoCarousel: carousel } }) =>
    carousel.productThumbnail.desktop.height +
    2 * carousel.productThumbnail.border}px;

  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.palette.white};
  width: var(--product-card-width);
  height: var(--product-card-height);
  flex-shrink: 0;
  align-self: flex-end;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  ${mobile`
    --product-card-width: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.productThumbnail.mobile.width +
      2 * carousel.productThumbnail.border}px;
    --product-card-height: ${({ theme: { videoCarousel: carousel } }) =>
      carousel.productThumbnail.mobile.height +
      2 * carousel.productThumbnail.border}px;
  `}

  ${({ active, theme: { colors } }) =>
    active &&
    `&::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors.shoppableVideoProductCardOverlay};
    }`}

  & picture {
    height: 100%;
  }

  & img {
    object-fit: cover;
    height: 100%;
  }
`;

export default function ProductCard({
  product,
  index,
  psrc = 'shoppable_video',
  ...rest
}) {
  const imageSets = useProductImages({ product });
  const productImage = imageSets[0];
  const router = useRouter();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);
  const { videoCarousel: cssHelper } = useTheme();

  const { observe, unobserve } = useProductListViewed();

  const { pdpUrl: href, productInfo } = useProductTracking({
    product,
    index,
    psrc,
  });

  useEffect(() => {
    const node = containerRef.current;
    if (node) {
      observe(node, productInfo);
      return () => unobserve(node);
    }
  }, [observe, unobserve, productInfo]);

  const redirectToPdp = () => {
    setIsActive(true);
    dispatch(
      trackProductClicked({
        ...productInfo,
        productUrl: href,
      })
    );
    router.push(href);
  };

  return (
    <ProductInfoContainer
      ref={containerRef}
      active={isActive}
      onClick={redirectToPdp}
      {...rest}
    >
      <Picture
        alt={product.label}
        sources={productImage}
        breakpoints={[
          {
            minWidth: 0,
            maxWidth: cssHelper.productThumbnail.mobile.width,
            pictureWidth: cssHelper.productThumbnail.mobile.width,
          },
        ]}
      />
    </ProductInfoContainer>
  );
}

ProductCard.propTypes = {
  index: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  psrc: PropTypes.string,
};
