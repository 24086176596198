export const tagIds = {
  LEAD_ONLY: 5310,
  VIP_ONLY: 5976,
};

export const MEMBERSHIP_STATES = {
  VISITOR: 'visitor',
  LEAD: 'lead',
  VIP: 'vip',
  ELITE: 'elite',
};

export const membershipExcludedTagIds = (membership) => {
  let filteredTagIds = [];
  switch (membership) {
    case MEMBERSHIP_STATES.ELITE:
    case MEMBERSHIP_STATES.VIP:
      filteredTagIds = [tagIds.LEAD_ONLY];
      break;
    case MEMBERSHIP_STATES.VISITOR:
    case MEMBERSHIP_STATES.LEAD:
      filteredTagIds = [tagIds.VIP_ONLY];
      break;
    default:
      break;
  }

  return filteredTagIds;
};

export const productDetailPath = 'products';
