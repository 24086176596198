import React from 'react';

export default function PlayIcon(props) {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="35.1572" cy="35.3696" r="35" fill="black" fillOpacity="0.5" />
      <path
        d="M51.8676 33.5339C53.7227 34.4764 53.7227 37.1264 51.8676 38.0689L27.6185 50.3891C25.9266 51.2487 23.9232 50.0194 23.9232 48.1216L23.9232 23.4812C23.9232 21.5834 25.9266 20.3541 27.6185 21.2137L51.8676 33.5339Z"
        fill="white"
      />
    </svg>
  );
}
