import { useEffect, useState } from 'react';

import { useNavElements } from '~/shared/utils/NavElements';

import useWaitForHeader from './useWaitForHeader';

/**
 * @description Hook to get the height of the header component.
 * @returns {number} The height of the header
 */
export default function useHeaderSize() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const { headerWrapperRef } = useNavElements();
  const isHeaderReady = useWaitForHeader();
  const builderHeaderHeight =
    headerWrapperRef?.current?.getBoundingClientRect()?.height;

  useEffect(() => {
    if (builderHeaderHeight) {
      setHeaderHeight(builderHeaderHeight);
    }
  }, [builderHeaderHeight, setHeaderHeight]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        if (height) {
          setHeaderHeight(height);
        }
      }
    });

    if (headerWrapperRef.current && isHeaderReady) {
      observer.observe(headerWrapperRef.current, {
        box: 'border-box',
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [headerWrapperRef, isHeaderReady]);

  return headerHeight;
}
