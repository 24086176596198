import { useMemo } from 'react';

import { productDetailPath } from '~/shared/constants/product';
import { buildURL } from '~/techstyle-shared/redux-core';

export function pdpBaseUrl(product, productDetailPath = 'products') {
  return `/${productDetailPath}/${
    product.permalink || product.masterProductId
  }`;
}

export function buildPdpUrl({ product, productDetailPath, psrc }) {
  return buildURL(pdpBaseUrl(product, productDetailPath), {
    psrc: psrc,
  });
}

export function buildProductInfo({ product, productDetailPath, index, psrc }) {
  return {
    product: {
      ...product,
      promos: {
        ...product.promos,
        promoLabel: product.promos?.promoLabel,
      },
    },
    href: pdpBaseUrl(product, productDetailPath),
    productSource: psrc,
    ...(index && { gridIndex: index }),
  };
}

export function useProductTracking({ product, index, psrc }) {
  const [href, productInfo] = useMemo(
    () => [
      buildPdpUrl({ product, productDetailPath, psrc }),
      buildProductInfo({ product, productDetailPath, index, psrc }),
    ],
    [product, psrc, index]
  );

  return { pdpUrl: href, productInfo: productInfo };
}
