import React from 'react';

import styled from 'styled-components';

import { skeletonGradient } from '~/shoppableVideo/utils';
import { SkeletonPulse } from '~/techstyle-shared/react-components';

const ProductCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  overflow-x: scroll;
  padding: 5px 0px;
  height: auto;
  display: flex;
  align-items: flex-start;
  gap: 14px;
  position: absolute;
  bottom: 50px;
  left: 0;
  padding: 0px 16px;
  white-space: nowrap;
  z-index: ${({ theme }) => theme.zIndexes.popover};
  overflow-y: hidden;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledSkeletonPulse = styled(SkeletonPulse)`
  ${skeletonGradient}
`;

const ProductCardSkeletonContainer = styled.div`
  position: relative;
  width: 53px;
  height: 80px;
`;

const ProductCardSkeleton = () => {
  return (
    <ProductCardSkeletonContainer>
      <StyledSkeletonPulse />
    </ProductCardSkeletonContainer>
  );
};

export default function LoadingProductsSkeleton(props) {
  return (
    <ProductCardsContainer {...props}>
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
      <ProductCardSkeleton />
    </ProductCardsContainer>
  );
}
