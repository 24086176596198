import { useEffect, useState } from 'react';

import { useNavElements } from '~/shared/utils/NavElements';

/**
 * @description Hook to wait for the header to be ready. This is useful when the header is rendered asynchronously from Builder.
 * @returns {boolean} `isHeaderReady` - Whether the header is ready or not
 */
export default function useWaitForHeader() {
  const { headerAttachmentContainerRef } = useNavElements();
  const [isHeaderReady, setIsHeaderReady] = useState(false);

  useEffect(() => {
    // Create a new mutation observer
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        // Check if the mutation is a childList mutation
        if (mutation.type === 'childList') {
          // Check if the mutation added any nodes
          if (mutation.addedNodes.length > 0) {
            if (headerAttachmentContainerRef.current) {
              setIsHeaderReady(true);
              observer.disconnect();
            }
          }
        }
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document, {
      childList: true,
      subtree: true,
      attributes: false,
    });

    // Clean up the observer on unmount
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerAttachmentContainerRef]);

  return isHeaderReady;
}
