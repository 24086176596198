import React from 'react';

import PropTypes from 'prop-types';

export default function VolumeOnIcon({
  color = 'currentColor',
  size = '27',
  ...rest
}) {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.1328 3.36426C13.6016 3.59863 13.8359 3.9502 13.875 4.49707V19.4971C13.8359 20.0439 13.6016 20.3955 13.1328 20.6299C12.9766 20.708 12.7812 20.7471 12.625 20.7471C12.3125 20.7861 12.0391 20.6299 11.8047 20.3174L6.53125 15.6299H3.25C2.70312 15.6299 2.27344 15.4736 1.92188 15.1221C1.57031 14.8096 1.375 14.3408 1.375 13.7939V10.083C1.375 9.5752 1.57031 9.14551 1.92188 8.79395C2.27344 8.48145 2.70312 8.28613 3.25 8.24707H6.53125L11.8047 3.55957C12.0391 3.36426 12.3125 3.24707 12.625 3.24707C12.7812 3.24707 12.9766 3.28613 13.1328 3.36426ZM12 18.0908V5.90332L7.23438 10.1611H3.25V13.8721H7.23438L12 18.0908ZM17.5078 9.10645C18.3672 9.8877 18.8359 10.8252 18.875 11.9971C18.8359 13.1689 18.3672 14.1455 17.5078 14.8877C17.3125 15.0439 17.1172 15.083 16.8828 15.083C16.5703 15.083 16.3359 15.0049 16.1797 14.7705C15.8281 14.3018 15.8672 13.833 16.2969 13.4424C16.7656 13.0518 17 12.583 17 11.9971C17 11.4502 16.7656 10.9424 16.2969 10.5518C15.9062 10.1611 15.8672 9.73145 16.1797 9.22363C16.3359 9.02832 16.5703 8.91113 16.8828 8.87207C17.1172 8.87207 17.3125 8.9502 17.5078 9.10645ZM19.8516 6.21582C20.7109 6.95801 21.4141 7.81738 21.8828 8.79395C22.3516 9.80957 22.625 10.9033 22.625 11.9971C22.625 13.1299 22.3516 14.1846 21.8828 15.2002C21.4141 16.2158 20.7109 17.0752 19.8516 17.7783C19.6562 17.9346 19.4609 17.9736 19.2656 17.9736C18.9531 17.9736 18.7188 17.8955 18.5234 17.6611C18.2109 17.1924 18.25 16.7236 18.6797 16.333C20.0078 15.2002 20.7109 13.7549 20.75 11.9971C20.7109 10.2783 20.0078 8.83301 18.6797 7.66113C18.25 7.27051 18.2109 6.84082 18.5234 6.33301C18.7188 6.1377 18.9531 6.02051 19.2656 6.02051C19.4609 6.02051 19.6562 6.09863 19.8516 6.21582Z"
        fill={color}
      />
    </svg>
  );
}

VolumeOnIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
