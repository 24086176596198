import React from 'react';

import { intervalToDuration } from 'date-fns';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) =>
    theme.colors.shoppableVideoProgressBackground};
  border-radius: 4px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.played.toFixed(2) * 100}%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.shoppableVideoLightGray};
    border-radius: 5px;
  }
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndexes.overlay};
  transition: opacity 0.5s ease-in-out;
  ${(props) =>
    props.$show &&
    css`
      opacity: 1;
    `}
`;

const TimerText = styled.p`
  color: ${({ theme }) => theme.colors.shoppableVideoLightGray};
  text-align: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.875em;
  letter-spacing: -0.5px;
`;

const formatDuration = (value = 0) => {
  const duration = intervalToDuration({ start: 0, end: value * 1000 });
  const zeroPad = (num) => String(num).padStart(2, '0');

  return [duration.minutes || 0, duration.seconds || 0].map(zeroPad).join(':');
};

export default function VideoProgress({
  show,
  elapsed,
  played,
  remaining,
  ...rest
}) {
  return (
    <Container $show={show} {...rest}>
      <TimerText>{formatDuration(elapsed)}</TimerText>
      <ProgressBar played={played} />
      <TimerText>-{formatDuration(remaining)}</TimerText>
    </Container>
  );
}

VideoProgress.propTypes = {
  elapsed: PropTypes.number.isRequired,
  played: PropTypes.number.isRequired,
  remaining: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
};
